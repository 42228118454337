/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ITextEditor {
  isSmall?: boolean;
  handleFocused?: (focused: boolean) => void;
  styleEditor?: any;
  showLinkPopupE?: boolean;
  defaultValue?: string;
  onChange?: (content: string) => void;
}

const TextEditorChoice: React.FC<ITextEditor> = ({
  isSmall = false,
  handleFocused,
  styleEditor,
  showLinkPopupE,
  defaultValue = '',
  onChange,
}) => {
  const [content, setContent] = useState<string>(defaultValue);
  const [showToolbar, setShowToolbar] = useState<boolean>(true);
  const [isFirst, setFisrt] = useState<boolean>(true);
  const editorRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (defaultValue !== '' && isFirst) {
      setContent(defaultValue);
      setFisrt(false);
    }
  }, [defaultValue, isFirst]);

  const handleChange = (value: string) => {
    setContent(value);
    if (onChange) {
      onChange(value);
    }
  };

  /* const handlePaste = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    const quill = editorRef.current?.getEditor();
    if (quill) {
      const range = quill.getSelection();
      quill.insertText(range?.index || 0, text);
    }
  };

  useEffect(() => {
    const quill = editorRef.current?.getEditor();
    if (quill) {
      quill.root.addEventListener('paste', handlePaste);
      return () => {
        quill.root.removeEventListener('paste', handlePaste);
      };
    }
  }, []); */

  const modules = {
    toolbar: showToolbar
      ? [
          [{ header: [1, 2, false] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: [] }],
          [{ color: [] }, { background: [] }],
          ['clean'],
        ]
      : false,
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    'color',
    'background',
  ];

  return (
    <>
      <div className="editor-container">
        <div>
          <ReactQuill
            ref={editorRef}
            value={content}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            placeholder="Cliquer pour ajouter du texte"
            style={{ height: isSmall ? '156px' : '334px' }}
          />
        </div>
        <br />
      </div>
      <style>{`
        .ql-container {
          line-height: 1.2;
        }
        .ql-editor {
          padding: 4px;
        }
      `}</style>
    </>
  );
};

export default TextEditorChoice;
